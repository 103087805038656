import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import {graphql} from "gatsby"
import ReactMarkdown from "react-markdown";
import Helmet from "react-helmet";

const ReferenzenPage = ({data})=> {
    let siteType = process.env.GATSBY_API_URL;
    let referencesData;
    if (siteType === "prod"){
        referencesData = data.allStrapiReferences.edges.filter(edges => edges.node.status === "published");
        referencesData.sort((a,b) => a.node.order - b.node.order);
    }
    else if (siteType === "dev"){
        referencesData = data.allStrapiReferences.edges.filter(edges => edges.node.status === "published" || edges.node.status === "staging");
        referencesData.sort((a,b) => a.node.order - b.node.order);
    }
    let offersData = data.allStrapiOfferPages.edges;
    return(
    <Layout page="reference">
        <Header className="reference" slogan ={data.allStrapiReferenzenPages.edges[0].node.slogan}
                blogphoto={{ backgroundImage: `url(${data.allStrapiReferenzenPages.edges[0].node.photo.publicURL})`}}
                offersData={offersData}/>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>Chatbot-Erfolge und Referenzen | Paixon</title>
            <meta name="description"  content="Das Entwickeln von massgeschneiderter Software ist seit Jahren unsere Leidenschaft. Die folgenden Projekte zeigen einen Auszug unserer Tätigkeit."/>
        </Helmet>
        <main className="container references pt">
            <div className="row">
                <div>
                    <ReactMarkdown children={data.allStrapiReferenzenPages.edges[0].node.chatbot_referenzen}/>
                            <div>
                                <div className="row logos our-projects">

                                    {referencesData.filter(edges => edges.node.type === "chatbot" && edges.node.size === "big")
                                        .map(edge => {
                                            const reference = edge.node;
                                                  return(
                                                <div className="col-md-4 col-sm-4 col-xs-12">

                                                    <a
                                                        className={`reference`}
                                                        style={{backgroundImage: `url(${reference.companyImage.publicURL})`}}
                                                        href={`/referenzen/${reference.url}`}
                                                        title={reference.title}
                                                    >
                                                        {" "}
                                                        {(reference.filmes.length > 0) && (
                                                            <div className='reference-logos-movie'>
                                                                <div className="reference-logos-movie-icon"></div>
                                                            </div>
                                                        )}
                                                    </a>
                                                </div>
                                            )})}
                                </div>
                                <div className="row logos our-projects small-references">
                                    {referencesData.filter(edges => edges.node.type === "chatbot" && edges.node.size === "small")
                                        .map(edge => {
                                            const reference = edge.node;
                                            return(
                                                <div className="col-md-4 col-sm-4 col-xs-6">
                                                    <a

                                                        className={`reference`}
                                                        style={{backgroundImage: `url(${reference.companyImage.publicURL})`}}
                                                        href={`/referenzen/${reference.url}`}
                                                        title={reference.title}
                                                    >
                                                        {" "}
                                                        {(reference.filmes.length > 0) && (
                                                            <div className='reference-logos-movie'>
                                                                <div className="reference-logos-movie-icon"></div>
                                                            </div>
                                                        )}
                                                    </a>
                                                </div>
                                            )})}

                                </div>
                                <ReactMarkdown children={data.allStrapiReferenzenPages.edges[0].node.software_referenzen}/>
                                <div className="row logos our-projects">
                                    {referencesData.filter(edges => edges.node.type === "software" && edges.node.size === "big")
                                        .map(edge => {
                                            const reference = edge.node;
                                            return(
                                                <div className="col-md-4 col-sm-4 col-xs-12">
                                                    <a

                                                        className={`reference`}
                                                        style={{backgroundImage: `url(${reference.companyImage.publicURL})`}}
                                                        href={`/referenzen/${reference.url}`}
                                                        title={reference.title}
                                                    >
                                                        {" "}
                                                        {(reference.filmes.length > 0) && (
                                                            <div className='reference-logos-movie'>
                                                                <div className="reference-logos-movie-icon"></div>
                                                            </div>
                                                        )}
                                                    </a>
                                                </div>
                                            )})}
                                </div>
                                <div className="row logos our-projects small-references">
                                    {referencesData.filter(edges => edges.node.type === "software" && edges.node.size === "small")
                                        .map(edge => {
                                            const reference = edge.node;
                                            return(
                                                <div className="col-md-4 col-sm-4 col-xs-6">
                                                    <a

                                                        className={`reference`}
                                                        style={{backgroundImage: `url(${reference.companyImage.publicURL})`}}
                                                        href={`/referenzen/${reference.url}`}
                                                        title={reference.title}
                                                    >
                                                        {" "}
                                                        {(reference.filmes.length > 0) && (
                                                            <div className='reference-logos-movie'>
                                                                <div className="reference-logos-movie-icon"></div>
                                                            </div>
                                                        )}
                                                    </a>
                                                </div>
                                            )})}
                                </div>
                            </div>
                </div>
            </div>
        </main>
    </Layout>
)}

export default ReferenzenPage


export const pageQuery = graphql`  
  query ReferenceQuery {
    allStrapiReferences {
    edges {
      node {
        id
        order
        url
        filmes {
      title
      url
    }
        created_at
        title
        type
        status
        size
        companyImage {
      publicURL
    }
      }
    }
  }
   allStrapiReferenzenPages {
    edges {
      node {
        photo {
          publicURL
        }
        slogan
        software_referenzen
        chatbot_referenzen
      }
    }
  }
  allStrapiOfferPages {
        edges {
            node {
                title
                url
                order
            }
        }
    }
  }
`
